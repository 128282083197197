
import { computed, defineComponent } from 'vue'


export default defineComponent({
  props: {
    image: {
      type: String,
      default: 'offer'
    },
    backgroundColor: {
      type: String,
      default: 'bg-teal-400'
    },
  },

  setup (props) {
    
    const offerImage = computed(() => {
      const image = require(`@/assets/img/${props.image}.png`)
      return `background-image: url('${image}')`
    })

    return {
      offerImage
    }
  }
})
